import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  name: "smsStaticTemplateList",

  data() {
    return {
      smsStaticTemplateList: [],
      searchText: "",
      showLoader: false,
      selected: "",
      selectedId: 0,
      selectedRows: [],
      sortColumn: "name",
      sortDirection: "asc",
      paginate: {
        page_count: 1,
        selected: 1,
        per_page: 50,
        current_page: 1,
        last_page: 0,
      },
      recordTakeOptions: [25, 50, 100, 200],
    };
  },
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.smsStaticTemplateList.length > 0) {
        return (
          _.where(this.smsStaticTemplateList, { isSelected: true }).length ===
          this.smsStaticTemplateList.length
        );
      }
      return false;
    },
    // hasBlogTagShowPermission() {
    //   const menuList = this.$store.state.menuList;
    //   const currentRoute = this.$route.path;
    //   let hasPermission = false;
    //   menuList.forEach((item) => {
    //     if (
    //       item.menu_link === currentRoute &&
    //       _.where(item.permissions, { name: "List" }).length > 0
    //     ) {
    //       hasPermission = true;
    //     } else if (item.child && item.child.length > 0) {
    //       item.child.forEach((element) => {
    //         if (
    //           element.menu_link === currentRoute &&
    //           _.where(element.permissions, { name: "List" }).length > 0
    //         ) {
    //           hasPermission = true;
    //         } else if (element.child && element.child.length > 0) {
    //           element.child.forEach((subElement) => {
    //             if (
    //               subElement.menu_link === currentRoute &&
    //               _.where(subElement.permissions, { name: "List" }).length > 0
    //             ) {
    //               hasPermission = true;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    //   return hasPermission;
    // },
    // hasBlogTagAddPermission() {
    //   const menuList = this.$store.state.menuList;
    //   const currentRoute = this.$route.path;
    //   let hasPermission = false;
    //   menuList.forEach((item) => {
    //     if (
    //       item.menu_link === currentRoute &&
    //       _.where(item.permissions, { name: "Store" }).length > 0
    //     ) {
    //       hasPermission = true;
    //     } else if (item.child && item.child.length > 0) {
    //       item.child.forEach((element) => {
    //         if (
    //           element.menu_link === currentRoute &&
    //           _.where(element.permissions, { name: "Store" }).length > 0
    //         ) {
    //           hasPermission = true;
    //         } else if (element.child && element.child.length > 0) {
    //           element.child.forEach((subElement) => {
    //             if (
    //               subElement.menu_link === currentRoute &&
    //               _.where(subElement.permissions, { name: "Store" }).length > 0
    //             ) {
    //               hasPermission = true;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    //   return hasPermission;
    // },
    // hasBlogTagDeletePermission() {
    //   const menuList = this.$store.state.menuList;
    //   const currentRoute = this.$route.path;
    //   let hasPermission = false;
    //   menuList.forEach((item) => {
    //     if (
    //       item.menu_link === currentRoute &&
    //       _.where(item.permissions, { name: "Delete" }).length > 0
    //     ) {
    //       hasPermission = true;
    //     } else if (item.child && item.child.length > 0) {
    //       item.child.forEach((element) => {
    //         if (
    //           element.menu_link === currentRoute &&
    //           _.where(element.permissions, { name: "Delete" }).length > 0
    //         ) {
    //           hasPermission = true;
    //         } else if (element.child && element.child.length > 0) {
    //           element.child.forEach((subElement) => {
    //             if (
    //               subElement.menu_link === currentRoute &&
    //               _.where(subElement.permissions, { name: "Delete" }).length > 0
    //             ) {
    //               hasPermission = true;
    //             }
    //           });
    //         }
    //       });
    //     }
    //   });
    //   return hasPermission;
    // },
  },
  methods: {
    updateStaticTemplateStatus(SST, event) {
      let _vm = this;
      let _msg =
        SST.isDisplay == true
          ? "Are you sure you want to Deactivate this Static Template?"
          : "Are you sure you want to Active this Static Template?";
      let _isDisplay = SST.isDisplay == true ? 1 : 0;
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post(
              "/sms-static-templates-update-status/id?id=" +
                SST.id +
                "&isDisplay=" +
                _isDisplay
            )
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById("regions-checkbox-" + SST.id).checked =
                SST.isDisplay;
            });
        })
        .catch(function () {
          document.getElementById("regions-checkbox-" + SST.id).checked =
            SST.isDisplay;
        });
    },
    checkUncheckedList(event) {
      this.smsStaticTemplateList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    copyStaticTemplate(smsTemplate, event) {
      let _vm = this;
      let _msg = "Are you sure you want to Duplicate this Static template ?";

      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post("/sms-static-templates/copy/" + smsTemplate.id)
            .then(function (response) {
              _vm.getAll();
              _vm.selectedId = 0;
            });
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific ID is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate through smsStaticTemplateList to find selected IDs
        this.smsStaticTemplateList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .post("/sms-static-templates-deletes/", { ids: ids })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    applySort(columnName) {
      if (this.sortColumn === columnName) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      this.getAll();
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send a GET request to retrieve tags
      this.axios
        .get("/sms-static-templates" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.smsStaticTemplateList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Add all checkbox IDs to selectedRows
          this.selectedRows = this.smsStaticTemplateList.map(
            (item) => "checkbox" + item.id
          );
          this.smsStaticTemplateList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Clear selectedRows and set isSelected to false for all elements
          this.selectedRows = [];
          this.smsStaticTemplateList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the checked checkbox ID to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the unchecked checkbox ID from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            // Filter out the unchecked checkbox ID
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
